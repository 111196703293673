<template>
  <div :class="`${prefixCls}-person`" id="aochenTalent">
    <Separate title="人才理念" desc="TALENT CONCEPT" class="wow animate__fadeInUp" data-wow-duration="1s" />
    <div
        :class="`${prefixCls}-person-content wow animate__fadeInUp`"
        data-wow-duration="2s"
        data-wow-delay="0.5s"
    >
      <div class="message-box" v-for="item in messageList" :key="item.id">
        <div class="content">
          <div class="desc">{{ item.desc }}</div>
          <div class="desc">{{ item.desc1 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Separate from '../../../components/Separate/index'
import { WowMixin } from '@/mixin/wow.js'
export default {
  name: 'CompanyDynamics',
  data () {
    return {
      prefixCls: this.$prefix + '-home',
      messageList: [
        {
          id: 1,
          img: require('@/assets/image/rcln1.jpg'),
          title: '人才发展愿景',
          desc: '金鳌集团“16 字标签”：集体至上、文化认同、事业激情、亲密战友把金鳌集团建成全体员工的一个家，是全体员工施展理想和抱负的平台，是身心的永远归宿，是疲劳时停泊休整的港湾，是困难时身心的依靠，是能为之奉献一生无悔的奋斗目标。',
          desc1: '秉承“尊重人、培养人、依靠人、满足人”的人才理念，坚持“以人为本”的文化理念，“多劳多得”的奖励方针，金鳌集团公司始终把人才视作企业发展的基础，并致力于将企业打造成人才聚集的高地，为各类人才提供了广阔的事业发展空间、优良的成长环境，使每一位有能力、有事业心的人都能在金鳌集团发挥自己的能力，实现自我价值。'
        }
        // {
        //   id: 2,
        //   img: require('@/assets/image/rcln2.jpg'),
        //   title: '人才发展战略',
        //   desc: '<p style="text-align: center">三年之内，重点引进和培养高素质的技术人才、营销人才和管理人才，<br>确保实现公司发展战略目标。</p>'
        // }
      ]
    }
  },
  mixins: [WowMixin],
  props: {
    introductionData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    Separate
  }
}
</script>

<style lang="scss" scoped>
$prefixCls: $namespace + -home-person;
.#{$prefixCls}{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: $content-width;
  padding-bottom: 50px;
  overflow: hidden;
  &-content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 80px);
    .message-box {
      margin-top: 20px;
      img {
        display: block;
        width: 100%;
      }
      .content {
        width: 100%;
          .desc {
            font-family: arial !important;
            font-size: 16px !important;
            color: #191818 !important;
            line-height: 40px !important;
            font-weight: lighter !important;
            text-align: justify;
            text-indent: 2em;
          }
      }
    }
  }
}
</style>
